import FeatureLayout from './FeatureLayout'

const HomePage = () => {
    return (
        <>
            <FeatureLayout />
        </>
    )
}

export default HomePage
