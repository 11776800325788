import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";



const firebaseConfig = {
  apiKey: "AIzaSyCCBfOFBYpFvRKMAdiqQ8ZsD78InOnudIY",
  authDomain: "psc-capsule.firebaseapp.com",
  projectId: "psc-capsule",
  storageBucket: "psc-capsule.appspot.com",
  messagingSenderId: "970108882893",
  appId: "1:970108882893:web:f5e7292e7d98a8413dfe84",
  measurementId: "G-GMSSTBN00X"
};

const app = initializeApp(firebaseConfig);
const authentication = getAuth();

export {app, authentication};
